import React from "react"

const PrescribingInfo = ({link, hideBoxed='', linkId}) => {
  return(
    <p className="pi-paragraph">
    Please see full{" "}
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      id={linkId}
    >
      Prescribing Information
    </a>
    <span className={hideBoxed}>, including Boxed WARNING</span>.
  </p>
  )
}
// PrescribingInfo.defaultProps = {
//     include: "Boxed WARNING",  
// }
export default PrescribingInfo;