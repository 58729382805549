import React, { useState, useEffect } from 'react'
import ExpandingMainContent from './ExpandingMainContent'
import "./expandingCard.scss"

function noOp(){}

/**
 * @param mainContent - The content that is always visible
 * @param hiddenContent - The content that is visible when the card or button is clicked
 * @returns {React component}
 */
const ExpandingCard = ({  mainContent, hiddenContent: HiddenContent, expandAll=false, collapseAll=false, reset=noOp, cardClass }) => {
  const [showHidden, setShowHidden] = useState(false)
  useEffect(() => {
    if (expandAll) {
      setShowHidden(true)
    }
    if (collapseAll) {
      setShowHidden(false)
    }
    console.log(cardClass)
  }, [collapseAll, expandAll, setShowHidden])

  return (
    <div className={`expanding-card ${cardClass}`}>
      <div className={"card-toggle"} 
      onClick={() => {
        reset()
        setShowHidden(!showHidden)
      }}></div>
      <ExpandingMainContent
        showHidden={showHidden}
        contentComponent={mainContent}
      />
      {showHidden && <HiddenContent/>}
    </div>
  )
}

export default ExpandingCard
