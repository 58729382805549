import React from "react"
import "./card.scss"
import { Link } from "gatsby"

import DownloadIcon from "../../images/icons/download-icon.svg"


function Card(props) {
    return (
        <div className={"card " +  props.cardClass}>
            {props.cardImage && <img src={props.cardImage} />}
            <h4>{props.cardTitle}</h4>
            <hr/>
            {props.cardText && <p>{props.cardText}</p>}
            
            
            {props.isInternalLink &&
                <Link id={props.linkId} to={props.linkUrl} className={props.linkClass} title={props.linkTitle}>
                    {props.linkText}{props.linkClass == "download-icon" && <img src={DownloadIcon} />}
                </Link>
            }
            {props.isExternalLink &&
            <div className="btn-container">
                <a Id={props.linkId} href={props.linkUrl} className={props.linkClass} title={props.linkTitle} target="_blank" rel="noreferrer">
                    {props.linkText}{props.linkClass == "download-icon" && <img src={DownloadIcon} />}
                </a>
            </div>
            }
        </div>
    );
}

export default Card